import Lottie from 'react-lottie'
import animationData from 'public/assets/lotties/404';
import Button from 'components/Button';

import styles from "styles/404.module.scss";
import { useRouter } from "next/router";

export default function FourOhFour() {
  const router = useRouter()
  
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const homeUrl = '/home'
  const cardQuizUrl = 'https://responda.plusdin.com.br/plusdin-recomendador-cartao-de-credito/index.html'

  return <>
    <div className={styles.container}>
      <div className={styles.content}>
        <section>
          <h1 className={styles.title}>Ops!</h1>
          <span>Não conseguimos achar a página que está procurando :/</span>
          <Button
            className={styles.primaryBtn}
            onClick={() => {router.push(homeUrl)}}
            type="button"
          >
            Ir para home
          </Button>
        </section>
        <section className={styles.quizContent}>
          <span>Que tal aproveitar para descrobrir qual é o cartão de crédito ideal para você?</span>
          <Button
            className={styles.primaryBtn}
            onClick={() => {router.push(cardQuizUrl)}}
            type="button"
            color="white"
          >
            Fazer o quiz
          </Button>
        </section>
      </div>
      <div 
        className={styles.lottie}
      >
        <Lottie
          options={lottieOptions}
          height={175}
          width={482.17}
        />
      </div>
    </div>
  </>
}